<template>
  <v-navigation-drawer
    v-model="model"
    app>
    <v-list
      dense
      rounded>
      <!-- Dashboard -->
      <v-list-item
        :to="{ name: $routes.home.dashboardRoute.name }"
        exact>
        <v-list-item-icon>
          <font-awesome-icon icon="home" />
        </v-list-item-icon>
        <v-list-item-title>
          Dashboard
        </v-list-item-title>
      </v-list-item>
      <!-- Domain -->
      <v-list-group v-if="superuser">
        <template #prependIcon>
          <font-awesome-icon :icon="['fab', 'chrome']" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("models.domains.multiple") }}</v-list-item-title>
        </template>
        <!-- List  -->
        <v-list-item
          :to="{ name: $routes.domain.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.list") }}
          </v-list-item-title>
        </v-list-item>
        <!-- Create  -->
        <v-list-item
          :to="{ name: $routes.domain.createRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.create") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Groups -->
      <v-list-group v-if="hasPrivileges(privileges.manageGroups)">
        <template #prependIcon>
          <font-awesome-icon icon="user" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("models.groups.multiple") }}</v-list-item-title>
        </template>
        <!-- List  -->
        <v-list-item
          :to="{ name: $routes.group.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.list") }}
          </v-list-item-title>
        </v-list-item>
        <!-- Create  -->
        <v-list-item
          :to="{ name: $routes.group.createRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.create") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Sales -->
      <v-list-group v-if="hasPrivileges(privileges.viewSales)">
        <template #prependIcon>
          <font-awesome-icon icon="dollar-sign" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("views.sales.title") }}</v-list-item-title>
        </template>
        <v-list-item
          :to="{ name: $routes.sale.dailyRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("views.sales.daily.title") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ name: $routes.sale.weeklyRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("views.sales.weekly.title") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ name: $routes.sale.historyRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("views.sales.history.title") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Tickets -->
      <v-list-group v-if="hasPrivileges(privileges.ticketMonitor)">
        <template #prependIcon>
          <font-awesome-icon icon="store" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("models.tickets.multiple") }}</v-list-item-title>
        </template>
        <v-list-item
          :to="{ name: $routes.ticket.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.monitor") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Betting Pools -->
      <v-list-group v-if="hasPrivileges([privileges.manageBettingPools, privileges.createBettingPools])">
        <template #prependIcon>
          <font-awesome-icon icon="store" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("models.bettingPools.multiple") }}</v-list-item-title>
        </template>
        <!-- List  -->
        <v-list-item
          v-if="hasPrivileges(privileges.manageBettingPools)"
          :to="{ name: $routes.bettingPool.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.list") }}
          </v-list-item-title>
        </v-list-item>
        <!-- Create  -->
        <v-list-item
          v-if="hasPrivileges([privileges.createBettingPools])"
          :to="{ name: $routes.bettingPool.createRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.create") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Round reports -->
      <v-list-group v-if="hasPrivileges(privileges.viewRoundsReport)">
        <template #prependIcon>
          <font-awesome-icon icon="archive" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("views.reports.title") }}</v-list-item-title>
        </template>
        <v-list-item
          :to="{ name: $routes.report.roundRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("views.reports.rounds.title") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Users -->
      <v-list-group v-if="hasPrivileges([privileges.manageUsers, privileges.manageAllUsers, privileges.createUsers, privileges.editUsers])">
        <template #prependIcon>
          <font-awesome-icon icon="user" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("models.users.multiple") }}</v-list-item-title>
        </template>
        <!-- List -->
        <v-list-item
          v-if="hasPrivileges([privileges.manageUsers, privileges.editUsers])"
          :to="{ name: $routes.user.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.list") }}
          </v-list-item-title>
        </v-list-item>
        <!-- Global List -->
        <v-list-item
          v-if="$store.getters.superuser"
          :to="{ name: $routes.user.globalIndexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.globalList") }}
          </v-list-item-title>
        </v-list-item>
        <!-- Create -->
        <v-list-item
          v-if="hasPrivileges([privileges.manageUsers, privileges.manageAllUsers, privileges.createUsers])"
          :to="{ name: $routes.user.createRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.create") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Transactions -->
      <v-list-group v-if="hasPrivileges(privileges.manageAccountableTransactions)">
        <template #prependIcon>
          <font-awesome-icon icon="credit-card" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("models.accountableTransactions.multiple") }}</v-list-item-title>
        </template>
        <v-list-item
          :to="{ name: $routes.accountableTransaction.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.list") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ name: $routes.accountableTransactionsGroup.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("actions.listPerGroups") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Zones -->
      <v-list-group v-if="hasPrivileges(privileges.manageZones)">
        <template #prependIcon>
          <font-awesome-icon icon="user" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("models.zones.multiple") }}</v-list-item-title>
        </template>
        <!-- List -->
        <v-list-item
          :to="{ name: $routes.zone.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.list") }}
          </v-list-item-title>
        </v-list-item>
        <!-- Create -->
        <v-list-item
          :to="{ name: $routes.zone.createRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.create") }}
          </v-list-item-title>
        </v-list-item>
        <!-- Manage -->
        <v-list-item
          :to="{ name: $routes.zone.manageRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.manage") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- Accountable entities -->
      <v-list-group v-if="hasPrivileges(privileges.manageAccountableEntities)">
        <template #prependIcon>
          <font-awesome-icon icon="landmark" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("models.accountableEntities.multiple") }}</v-list-item-title>
        </template>
        <!-- List -->
        <v-list-item
          :to="{ name: $routes.accountableEntity.indexRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.list") }}
          </v-list-item-title>
        </v-list-item>
        <!-- Create -->
        <v-list-item
          :to="{ name: $routes.accountableEntity.createRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("crudActions.create") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!-- My group -->
      <v-list-group v-if="hasPrivileges(privileges.manageMyGroup)">
        <template #prependIcon>
          <font-awesome-icon icon="store" />
        </template>
        <template #activator>
          <v-list-item-title>{{ $t("views.myGroup.title") }}</v-list-item-title>
        </template>
        <v-list-item
          :to="{ name: $routes.myGroup.configRoute.name }"
          exact>
          <v-list-item-content />
          <v-list-item-title>
            {{ $t("views.myGroup.config.title") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import Privilege from "@/imported/Privilege.json";

function data() {
  return {
    privileges: Privilege,
  };
};

export default {
  name: "AdministrationSidebar",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data,
  computed: {
    ...mapGetters(
      {
        superuser: "superuser",
        userPrivileges: "privileges",
      },
    ),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    hasPrivileges(privileges) {
      if (privileges instanceof Array) {
        return privileges.some((p) => this.userPrivileges.includes(p));
      }

      return this.userPrivileges.includes(privileges);
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item__icon {
  display: flex;
  align-items: center;
}
</style>
